body {
  margin: 0;
  padding: 0;
  font-family: Sans-Serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
